import {
  MerchantApi,
  OrderApi,
  PayoutApi,
  SettingsApi,
  StoreApi,
} from './generated';
import { setupInterceptorsTo } from './interceptors';
import axios from 'axios';

const config = {
  basePath: `https://${window.location.hostname}`,
  isJsonMime: () => false,
};

const API = {
  Store: new StoreApi(config),
  Merchant: new MerchantApi(config),
  Order: new OrderApi(config),
  Payout: new PayoutApi(config),
  Settings: new SettingsApi(config),
};

setupInterceptorsTo(axios);

export default API;
