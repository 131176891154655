import { PageContainer } from '../../components/PageContainer';
import * as styles from './Welcome.module.css';
import { LoadingButton } from '@mui/lab';
import { useQueryClient } from '@tanstack/react-query';
import { AppContext } from 'AppContext';
import API from 'api';
import { MerchantDto } from 'api/generated';
import { CurrencyLogo } from 'containers/CurrencyLogo';
import { LoginButton } from 'containers/LoginButton';
import wallet from 'images/wallet.png';
import wallet2x from 'images/wallet@2x.png';
import wallet3x from 'images/wallet@3x.png';
import walletPay from 'images/walletPay.png';
import walletPay2x from 'images/walletPay@2x.png';
import walletPay3x from 'images/walletPay@3x.png';
import { FC, useCallback, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, generatePath, useNavigate } from 'react-router-dom';
import { routePaths } from 'router';
import { useAppSelector } from 'store';
import { Auth, AuthStatuses } from 'utils/auth';
import { merchantHomePage } from 'utils/merchant';

export const Welcome: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { tgUser, storeId, jwtToken } = useAppSelector((state) => state.user);
  const { showSnackbar } = useContext(AppContext);
  const [authorizing, setAuthorizing] = useState(false);
  const queryClient = useQueryClient();

  function handleMerchant(merchant: MerchantDto) {
    navigate(merchantHomePage(merchant), { replace: true });
  }

  const onAuth = useCallback(
    async (auth: Auth) => {
      if (auth.status === AuthStatuses.SUCCESS) {
        setAuthorizing(false);
        handleMerchant(auth.merchant);
      } else if (auth.status === AuthStatuses.MERCHANT_NOT_FOUND) {
        const response = await API.Merchant.createMerchantForUser();
        await queryClient.invalidateQueries({ queryKey: ['merchant'] });
        setAuthorizing(false);
        handleMerchant(response.data.data as MerchantDto);
      } else if (auth.status === AuthStatuses.NOT_WALLET_USER) {
        setAuthorizing(false);
        navigate(routePaths.NOT_WALLET_USER, { replace: true });
      } else if (auth.status === AuthStatuses.ACCESS_DENIED) {
        setAuthorizing(false);
        showSnackbar({
          message: t('welcome.access_denied'),
        });
      } else {
        setAuthorizing(false);
        showSnackbar({
          message: t('common.something_went_wrong'),
        });
      }
    },
    [navigate, showSnackbar, t],
  );

  return (
    <PageContainer adaptive stretch>
      <div className={styles.root}>
        <div className={styles.content}>
          <div className={styles.assets}>
            <CurrencyLogo
              currency="TON"
              className="border-4 rounded-full border-white"
            />
            <CurrencyLogo
              currency="USDT"
              className="border-4 rounded-full border-white"
            />
            <CurrencyLogo
              currency="BTC"
              className="border-4 rounded-full border-white"
            />
          </div>
          <h1 className={styles.title}>{t('welcome.title')}</h1>
          <p className={styles.text}>{t('welcome.text')}</p>
          {!tgUser ? (
            <LoginButton
              onAuth={onAuth}
              onAuthStart={() => {
                setAuthorizing(true);
              }}
            />
          ) : (
            <LoadingButton
              loading={authorizing}
              size="large"
              variant="contained"
              component={Link}
              to={
                storeId
                  ? generatePath(routePaths.STORE, { id: storeId })
                  : jwtToken
                  ? routePaths.CREATE_STORE
                  : routePaths.NOT_WALLET_USER
              }
            >
              {storeId
                ? t('welcome.go_to_account')
                : jwtToken
                ? t('welcome.create_store')
                : t('welcome.start_wallet')}
            </LoadingButton>
          )}
        </div>
        <div className={styles.media}>
          <img
            className={styles.wallet}
            srcSet={`${wallet}, ${wallet2x} 2x, ${wallet3x} 3x`}
            alt="wallet"
          />
          <img
            className={styles.walletPay}
            srcSet={`${walletPay}, ${walletPay2x} 2x, ${walletPay3x} 3x`}
            alt="wallet pay"
          />
        </div>
      </div>
    </PageContainer>
  );
};
